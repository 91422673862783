import { t, Trans } from "@lingui/macro";

import { DollarSign } from "react-feather";
import styled from "styled-components";

import { formatMonthAndYear } from "~components/copied_from_shared/date";
import money from "~components/copied_from_shared/money";
import {
  baseUnit,
  black,
  borderColor,
  primary500,
} from "~components/copied_from_shared/pui";
import { Activity, BarChart2 } from "~components/copied_from_shared/pui/icons";
import { Typography } from "~components/tailwind/Typography";
import { useUser } from "~hooks";

import {
  ChartCard,
  ChartCardHeader,
  ChartCardTitle,
  ChartNoDataAvailable,
} from "../chart";
import { Filter } from "../types";
import { getPeriod } from "../utils";

import PaymentsAndChargesChart, {
  chartHeight,
} from "./PaymentsAndChargesChart";
import SummaryLegend from "./SummaryLegend";
import { ChartData } from "./types";

export const ChargedIcon = () => <BarChart2 color={primary500} />;
export const PaidIcon = () => <Activity color={black} />;

export const getPaidLabel = () => t`Paid`;
export const getChargedLabel = () => t`Charged`;

const getTitle = (currentFilter: Filter) => {
  if (
    currentFilter.type === "default" ||
    currentFilter.type === "last-30-days"
  ) {
    return t`Payments and charges, last 30 days`;
  } else if (currentFilter.type === "current-month") {
    const monthAndYear = formatMonthAndYear(new Date());
    return t`Payments and charges for ${monthAndYear}`;
  } else if (currentFilter.type === "custom") {
    const period = getPeriod(currentFilter.from, currentFilter.to);
    return t`Payments and charges from ${period}`;
  } else if (currentFilter.type === "last-7-days") {
    return t`Payments and charges, last 7 days`;
  }
};

export const Content = styled.div`
  height: ${chartHeight}px;
`;

export const Legends = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${baseUnit * 3}px;
  padding-top: ${baseUnit * 3}px;

  > *:not(:last-child) {
    margin-right: ${baseUnit * 6}px;
    padding-right: ${baseUnit * 6}px;
    border-right: 1px solid ${borderColor};
  }
`;

export type FeatureProps = {
  data: ChartData[];
  chargesAmount: string;
  paymentsAmount: string;
};

export type Props = FeatureProps & {
  currentFilter: Filter;
};

type HeaderProps = {
  currentFilter: Filter;
};
export const Header = ({ currentFilter }: HeaderProps) => (
  <ChartCardHeader>
    <div className="flex flex-col justify-start">
      <ChartCardTitle>
        <Trans>{getTitle(currentFilter)}</Trans>
      </ChartCardTitle>
    </div>
  </ChartCardHeader>
);

const PaymentsAndCharges = ({
  currentFilter,
  data,
  chargesAmount,
  paymentsAmount,
}: Props) => {
  const { viewAsUser } = useUser();
  const isEmpty = money(chargesAmount).add(money(paymentsAmount)).isZero;

  return (
    <ChartCard>
      <Header currentFilter={currentFilter} />
      <Content>
        {isEmpty ? (
          <ChartNoDataAvailable />
        ) : (
          <PaymentsAndChargesChart
            data={data}
            currency={viewAsUser.ownerCurrency}
          />
        )}
      </Content>
      <Legends>
        <SummaryLegend
          Icon={ChargedIcon}
          label={getChargedLabel()}
          amount={chargesAmount}
        />
        <SummaryLegend
          Icon={PaidIcon}
          label={getPaidLabel()}
          amount={paymentsAmount}
        />
      </Legends>
    </ChartCard>
  );
};

export default PaymentsAndCharges;
