import { useEffect, useState } from "react";

import { ContentLoader } from "~components";
import { serializeDate } from "~components/copied_from_shared/date";
import { useUser } from "~hooks";
import { fetch } from "~utils";

import { Filter } from "../types";
import { getFromAndToFromDashboardFilter } from "../utils";

import KeyNumbers from "./KeyNumbers";

import { Query, ResponseJson } from "~api/dashboard/key-numbers";
import KeyNumbersFallback from "~features/company/DashboardAlpha/KeyNumbers/KeyNumbersFallback";

type Props = {
  currentFilter: Filter;
};

const getBody = (query: Omit<Query, "descriptions">): string => {
  return JSON.stringify(query);
};

const KeyNumbersContainer = ({ currentFilter }: Props) => {
  const [data, setData] = useState<ResponseJson>();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState();
  const { viewAsUser } = useUser();

  const { from, to } = getFromAndToFromDashboardFilter(currentFilter);

  useEffect(() => {
    setLoading(true);
    fetch("/api/dashboard/key-numbers", {
      method: "POST",
      body: getBody({
        ownerId: viewAsUser.id,
        from: serializeDate(from),
        to: serializeDate(to),
        currency: viewAsUser.ownerCurrency,
      }),
    })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        setError(() => {
          throw error; // let error boundary handle this
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentFilter, viewAsUser.id]);

  if (loading || !data) {
    return <KeyNumbersFallback currentFilter={currentFilter} />;
  }

  return (
    <KeyNumbers
      rent={data.rent}
      depositAndPrepaidRent={data.depositAndPrepaidRent}
      consumptions={data.consumptions}
      total={data.total}
      currentFilter={currentFilter}
    />
  );
};

const KeyNumbersContainerLoader = (props: Props) => (
  <ContentLoader>
    <KeyNumbersContainer {...props} />
  </ContentLoader>
);

export default KeyNumbersContainerLoader;
