import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  baseUnit,
  boxShadow,
  Heading,
  Paragraph,
  white,
} from "~components/copied_from_shared/pui";

const ChartTooltipEmptyWrapper = styled.div`
  padding: 0 ${baseUnit * 3}px ${baseUnit}px ${baseUnit}px;
`;

export const ChartTooltipEmpty = () => (
  <ChartTooltipEmptyWrapper>
    <Paragraph>
      <Trans>No data</Trans>
    </Paragraph>
  </ChartTooltipEmptyWrapper>
);

const Wrapper = styled.div`
  box-shadow: ${boxShadow};
  background: ${white};
`;

const Label = styled(Heading).attrs({ size: "small" })`
  padding: ${baseUnit}px ${baseUnit}px 0 ${baseUnit}px;
  margin-bottom: ${baseUnit}px !important;
`;

type Props = {
  label: any;
  children: any;
};
export const ChartTooltip = ({ label, children }: Props) => (
  <Wrapper>
    {label && <Label>{label}</Label>}
    {children}
  </Wrapper>
);
