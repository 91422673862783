import { Trans } from "@lingui/macro";

import { useRef, useState } from "react";

import styled from "styled-components";

import { pageMaxWidth, pagePaddingHorizontal } from "~components/connected";
import { height as headerHeight } from "~components/connected/app/Header/Header";
import { appBackgroundColor } from "~components/connected/app/Providers/GlobalStyle";
import {
  baseUnit,
  borderColor,
  Paragraph,
} from "~components/copied_from_shared/pui";
import { useStickyDetection } from "~hooks";
import { getElementStackOrder } from "~utils";

import DistributionOfCharges from "./DistributionOfCharges";
import Filters from "./Filters";
import KeyNumbers from "./KeyNumbers";
import PaymentsAndCharges from "./PaymentsAndCharges";
import PortfolioRightNow from "./PortfolioRightNow";
import { Filter } from "./types";

const Section = styled.div`
  margin-bottom: ${baseUnit * 3}px;
`;

const Sections = styled.div`
  max-width: ${pageMaxWidth}px;
  margin: 0 auto;
  padding: ${baseUnit * 2}px ${pagePaddingHorizontal}px ${baseUnit * 4}px
    ${pagePaddingHorizontal}px;
`;

const SectionCaption = styled(Paragraph).attrs({ look: "discreet" })`
  margin-bottom: ${baseUnit * 1.5}px;
`;

const FiltersOuter = styled.div<{ isSticky: boolean }>`
  margin-bottom: ${baseUnit * 3}px;
  display: flex;
  align-items: center;
  position: sticky;
  top: ${headerHeight};
  height: ${headerHeight};
  margin-top: ${baseUnit * 2}px;
  background: ${appBackgroundColor};
  z-index: ${getElementStackOrder("dashboard-filters")};
  transition: border-bottom 200ms;
  border-bottom: ${({ isSticky }) =>
    isSticky ? `1px solid ${borderColor}` : "1px solid transparent"};
`;

const FiltersInner = styled.div`
  padding: 0 ${pagePaddingHorizontal}px;
  max-width: ${pageMaxWidth}px;
  width: ${pageMaxWidth}px;
  margin: 0 auto;
`;

const StickyDetector = styled.div`
  min-height: 0px;
  min-width: 0px;
`;

const Dashboard = () => {
  const [currentFilter, setCurrentFilter] = useState<Filter>({
    type: "default",
  });
  const stickyDetectorRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyDetection(stickyDetectorRef);

  return (
    <>
      <StickyDetector ref={stickyDetectorRef} />
      <FiltersOuter isSticky={isSticky}>
        <FiltersInner>
          <Filters
            onChangeFilter={setCurrentFilter}
            currentFilter={currentFilter}
          />
        </FiltersInner>
      </FiltersOuter>
      <Sections>
        <Section>
          <KeyNumbers currentFilter={currentFilter} />
        </Section>
        <Section>
          <PaymentsAndCharges currentFilter={currentFilter} />
        </Section>
        <Section>
          <DistributionOfCharges currentFilter={currentFilter} />
        </Section>
        <Section>
          <SectionCaption>
            <Trans>Your portfolio, right now</Trans>
          </SectionCaption>
          <PortfolioRightNow />
        </Section>
      </Sections>
    </>
  );
};

export default Dashboard;
