import { t } from "@lingui/macro";

import { Children, cloneElement } from "react";

import { Popover, usePopoverState } from "~components/copied_from_shared/pui";
import { Calendar } from "~components/copied_from_shared/pui/icons";

import { Filter as FilterType } from "../types";
import { getPeriod } from "../utils";

import { DateRange } from "./DateRangePicker/types";
import DateRangePicker from "./DateRangePicker";
import Filter from "./Filter";

export const Wrapper = ({ children }: any) => {
  return (
    <div style={{ display: "flex" }}>
      {Children.map(children, (child, index) => cloneElement(child, { index }))}
    </div>
  );
};

export type Props = {
  onChangeFilter: (filter: FilterType) => void;
  currentFilter: FilterType;
};

const Filters = ({ onChangeFilter, currentFilter }: Props) => {
  const popover = usePopoverState({
    placement: "bottom-start",
    unstable_preventOverflow: false,
  });
  const onApplyDateRange = ({ to, from }: DateRange) => {
    popover.hide();
    if (to && from) {
      onChangeFilter({ type: "custom", to, from });
    } else {
      onChangeFilter({ type: "default" });
    }
  };

  const onDatePickerClose = () => {
    popover.hide();
  };

  return (
    <Wrapper>
      <Popover
        state={popover}
        ariaLabel={t`Choose custom date range`}
        trigger={
          <Filter
            Icon={Calendar}
            isActive={currentFilter?.type === "custom"}
            label={
              currentFilter?.type === "custom"
                ? getPeriod(currentFilter.from, currentFilter.to)
                : t`Custom`
            }
          />
        }
      >
        <DateRangePicker
          dateRange={
            currentFilter.type === "custom"
              ? ({
                  from: currentFilter.from,
                  to: currentFilter.to,
                } as DateRange)
              : null
          }
          onApplyDateRange={onApplyDateRange}
          onClose={onDatePickerClose}
        />
      </Popover>
      <Filter
        isActive={currentFilter?.type === "last-7-days"}
        onClick={() => onChangeFilter({ type: "last-7-days" })}
        label={t`Last 7 days`}
      />
      <Filter
        isActive={currentFilter?.type === "last-30-days"}
        onClick={() => onChangeFilter({ type: "last-30-days" })}
        label={t`Last 30 days`}
      />
      <Filter
        isActive={currentFilter?.type === "current-month"}
        onClick={() => onChangeFilter({ type: "current-month" })}
        label={t`Current month`}
      />
      <Filter
        isActive={currentFilter.type === "default"}
        onClick={() => onChangeFilter({ type: "default" })}
        label={t`Default`}
      />
    </Wrapper>
  );
};

export default Filters;
