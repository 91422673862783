import styled, { css } from "styled-components";

import { baseUnit } from "~components/copied_from_shared/pui";

type Props = {
  horizontal: boolean;
};
const Grid = styled.div<Props>`
  display: grid;

  ${({ horizontal }) =>
    horizontal
      ? css`
          grid-auto-columns: 1fr;
          grid-auto-flow: column;
          grid-column-gap: ${baseUnit * 2}px;
        `
      : css`
          grid-auto-rows: 1fr;
          grid-auto-flow: row;
          grid-row-gap: ${baseUnit * 2}px;
        `}
`;

export default Grid;
