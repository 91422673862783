import { t } from "@lingui/macro";

import StatsBox from "~components/StatsBoxAlpha";
import useDimensions from "~hooks/useDimensions";

import Grid from "../Grid";

export const labels = {
  tenancies: () => t`Tenancies`,
  occupied: () => t`Occupied`,
  totalArea: () => t`Total area`,
  businessArea: () => t`Business area`,
};

export const Wrapper = ({ children }: any) => {
  const { ref, width } = useDimensions();
  return (
    <Grid ref={ref} horizontal={width > 800}>
      {children}
    </Grid>
  );
};

export type Props = {
  numTenancies: number;
  numOccupiedTenancies: number;
  hasTenanciesWithoutTotalArea: boolean;
  hasTenanciesWithoutBusinessArea: boolean;
  tenanciesAreaTotal: number;
  tenanciesBusinessAreaTotal: number;
};

const PortfolioRightNow = ({
  numTenancies,
  numOccupiedTenancies,
  hasTenanciesWithoutTotalArea,
  hasTenanciesWithoutBusinessArea,
  tenanciesAreaTotal,
  tenanciesBusinessAreaTotal,
}: Props) => {
  return (
    <Wrapper>
      <StatsBox size="small" label={labels.tenancies()} value={numTenancies} />
      <StatsBox
        size="small"
        label={labels.occupied()}
        value={numOccupiedTenancies}
      />
      <StatsBox
        size="small"
        label={labels.totalArea()}
        value={`${tenanciesAreaTotal} m²`}
        help={
          hasTenanciesWithoutTotalArea
            ? t`Some tenancies don't have a total area`
            : null
        }
      />
      <StatsBox
        size="small"
        label={labels.businessArea()}
        value={`${tenanciesBusinessAreaTotal} m²`}
        help={
          hasTenanciesWithoutBusinessArea
            ? t`Some tenancies don't have a business area`
            : null
        }
      />
    </Wrapper>
  );
};

export default PortfolioRightNow;
