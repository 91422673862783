import { t, Trans } from "@lingui/macro";

import { TooltipProps } from "recharts";

import { Typography } from "~components/tailwind/Typography";
import { useUser } from "~hooks";
import { moneyFromNumber } from "~utils/money";

import { ChartTooltip, ChartTooltipEmpty, Legend } from "../chart";
import {
  colorConsumptions,
  colorDepositAndPrepaidRent,
  colorPayments,
  colorRent,
} from "../tokens";

type Props = TooltipProps<any, any>;
const Tooltip = (props: Props) => {
  const { viewAsUser } = useUser();
  const currency = viewAsUser.ownerCurrency;

  let content;
  const item = props.payload?.find(({ value }) => !!value);
  if (!item) {
    content = <ChartTooltipEmpty />;
  } else {
    const { rent, depositAndPrepaidRent, consumptions, payments } =
      item?.payload || {};
    content = (
      <>
        <Legend
          color={colorPayments}
          label={t`Payments`}
          value={moneyFromNumber(payments, currency).format()}
        />
        <Legend
          color={colorRent}
          label={t`Rent`}
          value={moneyFromNumber(rent, currency).format()}
        />
        <Legend
          color={colorDepositAndPrepaidRent}
          label={t`Deposit and prepaid rent`}
          value={moneyFromNumber(depositAndPrepaidRent, currency).format()}
        />
        <Legend
          color={colorConsumptions}
          label={t`Aconto, operations, etc.`}
          value={moneyFromNumber(consumptions, currency).format()}
        />
      </>
    );
  }

  let label;
  if (!item) {
    label = props.label;
  } else {
    label = (
      <div className="flex items-baseline justify-between">
        {props.label}
        <div className="flex items-center justify-end">
          <Typography color="gray" tone={600} alignRight small>
            <Trans>Amount including VAT</Trans>
          </Typography>
        </div>
      </div>
    );
  }

  return <ChartTooltip label={label}>{content}</ChartTooltip>;
};

export default Tooltip;
