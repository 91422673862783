import styled from "styled-components";

import {
  baseUnit,
  borderColorDiscreet,
  Paragraph,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${baseUnit / 2}px ${baseUnit}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${borderColorDiscreet};
  }
`;

const LegendDot = styled.div<{ color: string }>`
  margin-right: ${baseUnit / 2}px;
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color};
  border-radius: 3px;
`;

const Label = styled(Paragraph)`
  margin-right: ${baseUnit * 6}px;
`;

const Value = styled(Paragraph)`
  margin-left: auto;
`;

export type Props = {
  color: string;
  label: string;
  value: string;
  style?: Record<string, unknown>;
};

const Legend = ({ color, label, value, ...rest }: Props) => (
  <Wrapper {...rest}>
    <LegendDot color={color} />
    <Label>{label}</Label>
    <Value>
      <strong>{value}</strong>
    </Value>
  </Wrapper>
);

export default Legend;
