import { t } from "@lingui/macro";

import { useState } from "react";

import { orderBy } from "lodash";
import styled from "styled-components";

import {
  blue200,
  blue300,
  blue500,
  blue600,
  blue700,
  gray200,
  gray300,
  gray500,
  gray600,
  green200,
  green300,
  green500,
  green600,
  green700,
  orange200,
  orange300,
  orange500,
  orange600,
  orange700,
  pampas200,
  pampas300,
  pampas500,
  pampas600,
  pampas700,
  red200,
  red300,
  red500,
  red600,
  red700,
  yellow200,
  yellow300,
  yellow500,
  yellow600,
  yellow700,
} from "~components/copied_from_shared/pui";
import { useProducts } from "~hooks";
import { OwnerCurrency } from "~services/entities";

import { ChartCard, ChartNoDataAvailable } from "../chart";
import { Filter } from "../types";

import DistributionOfChargesChart, {
  chartHeight,
} from "./DistributionOfChargesChart";
import DistributionOfChargesHeader from "./DistributionOfChargesHeader";
import Filters from "./Filters";
import { ChartData, Description, DescriptionObject } from "./types";

const numDefaultDescriptions = 4;

const getFilteredData = (
  chartData: ChartData[],
  selectedDescriptions: Description[]
) => {
  const filteredChartData: ChartData[] = [];

  chartData.forEach((item) => {
    const data: ChartData = { date: item.date };

    Object.keys(item).forEach((description) => {
      if (selectedDescriptions.find((d) => d === description)) {
        data[description as Description] = item[description as Description];
      }
    });

    filteredChartData.push(data);
  });

  return filteredChartData;
};

export const Content = styled.div`
  display: flex;
  height: ${chartHeight}px;
`;

export type FeatureProps = ChartData[];

export type Props = {
  availableDescriptionTypes: Description[];
  chartData: ChartData[];
  currency: OwnerCurrency;
  currentFilter: Filter;
};

const colorsByRelevance = [
  blue500,
  blue300,
  pampas500,
  pampas300,
  green500,
  green300,
  yellow500,
  yellow300,
  red500,
  red300,
  gray500,
  gray300,
  orange500,
  orange300,
  blue200,
  blue600,
  pampas200,
  pampas600,
  green200,
  green600,
  yellow200,
  yellow600,
  red200,
  red600,
  gray200,
  gray600,
  orange200,
  orange600,
  blue700,
  pampas700,
  green700,
  yellow700,
  red700,
  orange700,
];

export const getChargeDescriptions = (
  getProductName: (name: string) => string,
  availableTypes: Description[]
): DescriptionObject[] => {
  return availableTypes.map((type, index) => {
    if (type === "other") {
      return {
        type,
        color: colorsByRelevance[index],
        displayName: getProductName(type),
        help: t`All custom transaction types`,
        index: index,
      };
    }
    return {
      type,
      color: colorsByRelevance[index],
      displayName: getProductName(type),
      index: index,
    };
  });
};

const DistributionOfCharges = ({
  currentFilter,
  chartData,
  currency,
  availableDescriptionTypes,
}: Props) => {
  const { getProductName } = useProducts();
  const descriptions = getChargeDescriptions(
    getProductName,
    availableDescriptionTypes
  );
  const defaultDescriptionTypes = descriptions
    .slice(0, numDefaultDescriptions)
    .map((d) => d.type);
  const [selectedDescriptionTypes, setSelectedDescriptionTypes] = useState<
    Description[]
  >(defaultDescriptionTypes);

  const filteredChartData = getFilteredData(
    chartData,
    selectedDescriptionTypes
  );
  const hasNoData = descriptions.length === 0;
  const selectedDescriptions = descriptions.filter((d) =>
    selectedDescriptionTypes.includes(d.type)
  );

  const onToggleDescriptionType = (type: Description) => {
    if (selectedDescriptionTypes.find((d) => d === type)) {
      setSelectedDescriptionTypes(
        selectedDescriptionTypes.filter((d) => d !== type)
      );
    } else {
      setSelectedDescriptionTypes([...selectedDescriptionTypes, type]);
    }
  };

  if (hasNoData) {
    return (
      <ChartCard>
        <DistributionOfChargesHeader currentFilter={currentFilter} />
        <Content>
          <ChartNoDataAvailable />
        </Content>
      </ChartCard>
    );
  }

  return (
    <ChartCard>
      <DistributionOfChargesHeader currentFilter={currentFilter} />
      <Content>
        {selectedDescriptionTypes.length === 0 ? (
          <ChartNoDataAvailable />
        ) : (
          <DistributionOfChargesChart
            selectedDescriptions={orderBy(
              selectedDescriptions,
              ["index", "displayName"],
              ["asc", "asc"]
            )}
            currency={currency}
            data={filteredChartData}
          />
        )}
        <Filters
          availableDescriptionTypes={availableDescriptionTypes}
          onToggleDescriptionType={onToggleDescriptionType}
          selectedDescriptionTypes={selectedDescriptionTypes}
          setSelectedDescriptions={setSelectedDescriptionTypes}
          defaultDescriptionTypes={defaultDescriptionTypes}
          descriptions={orderBy(
            descriptions,
            ["index", "displayName"],
            ["asc", "asc"]
          )}
        />
      </Content>
    </ChartCard>
  );
};

export default DistributionOfCharges;
