import styled from "styled-components";

import {
  baseUnit,
  black,
  fontFamilySans,
  fontFamilySerif,
  fontStack,
  fontWeightBold,
  textColor,
} from "~components/copied_from_shared/pui";
import money from "~utils/money";

const iconSize = "24px";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    flex: ${iconSize};
    width: ${iconSize};
    height: ${iconSize};
  }
`;

export const Content = styled.div`
  margin-left: ${baseUnit}px;
`;

export const Label = styled.div`
  ${fontStack};
  font-family: ${fontFamilySans};
  font-size: 17px;
  margin-bottom: ${baseUnit / 2}px;
  color: ${textColor};
`;

export const Amount = styled.div`
  ${fontStack};
  color: ${black};
  font-family: ${fontFamilySerif};
  font-weight: ${fontWeightBold};
  font-size: 24px;
  line-height: ${iconSize};
`;

export type Props = { Icon: any; label: string; amount: string };

const SummaryLegend = ({ Icon, label, amount }: Props) => (
  <Wrapper>
    <Icon />
    <Content>
      <Label>{label}</Label>
      <Amount>{money(amount).format()}</Amount>
    </Content>
  </Wrapper>
);

export default SummaryLegend;
