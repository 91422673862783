import { t } from "@lingui/macro";

import { Wrapper as Filters } from "../../Filters";
import Filter from "../../Filters/Filter";

export type FilterType = "default" | "all" | "none" | "custom";
export type Props = {
  numDefaultDescriptions: number;
  numdescriptions: number;
  onChangeFilter: (filter: FilterType) => void;
  currentFilter: FilterType;
};

const FilterActions = ({
  onChangeFilter,
  currentFilter,
  numdescriptions,
  numDefaultDescriptions,
}: Props) => (
  <Filters>
    <Filter
      small
      isActive={currentFilter === "default"}
      onClick={() => onChangeFilter("default")}
      label={t`Default (${numDefaultDescriptions})`}
    />
    <Filter
      small
      isActive={currentFilter === "all"}
      onClick={() => onChangeFilter("all")}
      label={t`All (${numdescriptions})`}
    />
    <Filter
      small
      isActive={currentFilter === "none"}
      onClick={() => onChangeFilter("none")}
      label={t`None`}
    />
  </Filters>
);

export default FilterActions;
