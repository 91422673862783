import styled from "styled-components";

import { Loader } from "~components";

import { ChartCard } from "../chart";
import { Filter } from "../types";

import {
  ChargedIcon,
  getChargedLabel,
  getPaidLabel,
  Header,
  Legends,
  PaidIcon,
} from "./PaymentsAndCharges";
import { chartHeight } from "./PaymentsAndChargesChart";
import SummaryLegendFallback from "./SummaryLegendFallback";

const ChartFallback = styled.div`
  height: ${chartHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  currentFilter: Filter;
};
const PaymentsAndChargesFallback = ({ currentFilter }: Props) => {
  return (
    <ChartCard>
      <Header currentFilter={currentFilter} />
      <ChartFallback>
        <Loader />
      </ChartFallback>
      <Legends>
        <SummaryLegendFallback Icon={ChargedIcon} label={getChargedLabel()} />
        <SummaryLegendFallback Icon={PaidIcon} label={getPaidLabel()} />
      </Legends>
    </ChartCard>
  );
};

export default PaymentsAndChargesFallback;
