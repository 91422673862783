import styled from "styled-components";

import { Loader } from "~components";

import { ChartCard } from "../chart";
import { Filter } from "../types";

import { chartHeight } from "./DistributionOfChargesChart";
import DistributionOfChargesHeader from "./DistributionOfChargesHeader";

const ChartFallback = styled.div`
  height: ${chartHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  currentFilter: Filter;
};
const DistributionOfChargesFallback = ({ currentFilter }: Props) => {
  return (
    <ChartCard>
      <DistributionOfChargesHeader currentFilter={currentFilter} />
      <ChartFallback>
        <Loader />
      </ChartFallback>
    </ChartCard>
  );
};

export default DistributionOfChargesFallback;
