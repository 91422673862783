import { isBefore } from "date-fns";

import { Property, TenancySummary, TenancySummaryLease } from "~types";

export const isVacant = (tenancy: TenancySummary, now: Date) => {
  if (tenancy.activeLease) {
    return false;
  } else if (tenancy.nextLease?.contractStartDate) {
    // We often onboard (take over) leases that are already started. So
    // when there is a next lease with a contract start date in the past,
    // we consider it occupied (from a UX perspective).
    return !isBefore(new Date(tenancy.nextLease.contractStartDate), now);
  }

  return true;
};

export const soonToBeVacantMonths = 3;

export const getNumTenancies = (allProperties: Property[]) => {
  let numTenancies = 0;

  allProperties.forEach((property) => {
    numTenancies += property.tenancies.length;
  });

  return numTenancies;
};

export const getNumVacantTenancies = (allProperties: Property[]) => {
  let numVacantTenancies = 0;

  allProperties.forEach((property) => {
    property.tenancies.forEach((tenancy) => {
      if (isVacant(tenancy, new Date())) {
        numVacantTenancies += 1;
      }
    });
  });

  return numVacantTenancies;
};
