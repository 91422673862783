import { useEffect, useState } from "react";

import styled from "styled-components";

import {
  baseUnit,
  borderColor,
  white,
} from "~components/copied_from_shared/pui";
import Checkbox from "~components/copied_from_shared/pui/components/form/Checkbox";
import FormElement from "~components/copied_from_shared/pui/components/form/Form/FormElement";

import { Description, DescriptionObject } from "../types";

import FilterActions, { FilterType } from "./FilterActions";

const Wrapper = styled.div`
  overflow: scroll;
  border-left: 1px solid ${borderColor};
  margin-left: ${baseUnit * 3}px;
  padding-left: ${baseUnit * 3}px;
`;

const Actions = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background: ${white};
  z-index: 1;
  padding-bottom: ${baseUnit * 2}px;

  > *:not(:last-child) {
    margin-right: ${baseUnit}px;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: ${baseUnit / 2}px;
  }
`;

type Props = {
  setSelectedDescriptions: (descriptions: Description[]) => void;
  descriptions: DescriptionObject[];
  defaultDescriptionTypes: Description[];
  selectedDescriptionTypes: Description[];
  availableDescriptionTypes: Description[];
  onToggleDescriptionType: (descriptionType: Description) => void;
};
const Filters = ({
  availableDescriptionTypes,
  defaultDescriptionTypes,
  selectedDescriptionTypes,
  descriptions,
  onToggleDescriptionType,
  setSelectedDescriptions,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>("default");
  const onChangeFilter = (filter: FilterType) => {
    if (filter === "all") {
      setSelectedDescriptions(availableDescriptionTypes);
    } else if (filter === "default") {
      setSelectedDescriptions(defaultDescriptionTypes);
    } else {
      setSelectedDescriptions([]);
    }
  };

  useEffect(() => {
    if (selectedDescriptionTypes.length === availableDescriptionTypes.length) {
      setCurrentFilter("all");
    } else if (
      selectedDescriptionTypes.length === defaultDescriptionTypes.length
    ) {
      setCurrentFilter("default");
    } else if (selectedDescriptionTypes.length === 0) {
      setCurrentFilter("none");
    } else {
      setCurrentFilter("custom");
    }
  }, [selectedDescriptionTypes.length]);

  return (
    <Wrapper>
      <Actions>
        <FilterActions
          currentFilter={currentFilter}
          numDefaultDescriptions={defaultDescriptionTypes.length}
          numdescriptions={descriptions.length}
          onChangeFilter={onChangeFilter}
        />
      </Actions>
      <List>
        {descriptions.map(({ color, type, help, displayName }) => {
          const isChecked = !!selectedDescriptionTypes.find((d) => d === type);
          const style = isChecked
            ? { backgroundColor: color, borderColor: color }
            : undefined;

          return (
            <Item key={`filter-${type}`}>
              <FormElement
                layout="horizontal-inverse"
                id={type}
                help={help}
                label={displayName}
              >
                <Checkbox
                  // @ts-ignore TODO: Not sure if we should just allow style
                  // overrides, clone the checkbox or just suppress this error.
                  // Might also be that we go another direction with the UI. So
                  // I think this is fine for now.
                  style={style}
                  onChange={() => onToggleDescriptionType(type)}
                  checked={isChecked}
                />
              </FormElement>
            </Item>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default Filters;
