import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

import { Link } from "~components/connected";
import { formatMonthAndYear } from "~components/copied_from_shared/date";
import { Paragraph } from "~components/copied_from_shared/pui";
import { ArrowRight } from "~components/copied_from_shared/pui/icons";

import { ChartCardAnchor, ChartCardHeader, ChartCardTitle } from "../chart";
import { Filter } from "../types";
import { getPeriod } from "../utils";

import { ChartData } from "./types";

const getFilterText = (currentFilter: Filter) => {
  if (currentFilter.type === "default") {
    return t`Distribution of charges, last 12 months`;
  } else if (currentFilter.type === "last-30-days") {
    return t`Distribution of charges, last 30 days`;
  } else if (currentFilter.type === "current-month") {
    const month = formatMonthAndYear(new Date());
    return t`Distribution of charges for ${month}`;
  } else if (currentFilter.type === "custom") {
    const period = getPeriod(currentFilter.from, currentFilter.to);
    return t`Distribution of charges from ${period}`;
  } else if (currentFilter.type === "last-7-days") {
    return t`Distribution of charges, last 7 days`;
  }
};

export type FeatureProps = ChartData[];

type HeaderProps = {
  currentFilter: Filter;
};
const DistributionOfChargesHeader = ({ currentFilter }: HeaderProps) => (
  <ChartCardHeader>
    <ChartCardTitle>
      <Trans>{getFilterText(currentFilter)}</Trans>
    </ChartCardTitle>
    <Paragraph>
      <Link to="/reports/lease-charge-summary">
        <ChartCardAnchor>
          <Trans>
            Generate report <ArrowRight size={18} />
          </Trans>
        </ChartCardAnchor>
      </Link>
    </Paragraph>
  </ChartCardHeader>
);

export default DistributionOfChargesHeader;
