import { t } from "@lingui/macro";

import { TooltipProps } from "recharts";

import { gray100, gray500 } from "~components/copied_from_shared/pui";
import { OwnerCurrency } from "~services/entities";
import { add } from "~utils/money";
import money, { moneyFromNumber } from "~utils/money";

import { ChartTooltip, ChartTooltipEmpty, Legend } from "../chart";

type Props = TooltipProps<any, any> & { currency: OwnerCurrency };
const Tooltip = ({ payload, label, currency }: Props) => {
  if (!payload?.find(({ value }) => !!value)) {
    return (
      <ChartTooltip label={label}>
        <ChartTooltipEmpty />
      </ChartTooltip>
    );
  }

  const total = payload
    .map(({ value }) => moneyFromNumber(Number(value), currency).getIsoFormat())
    .reduce(add);

  return (
    <ChartTooltip label={label}>
      {payload.map(({ color, name, value, dataKey }) => (
        <Legend
          key={`legend-${dataKey}`}
          color={color || gray500}
          label={name}
          value={moneyFromNumber(Number(value), currency).format()}
        />
      ))}
      <Legend
        style={{ backgroundColor: gray100 }}
        color="transparent"
        label={t`Total`}
        value={money(total).format()}
      />
    </ChartTooltip>
  );
};

export default Tooltip;
