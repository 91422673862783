import { Skeleton } from "~components/ContentLoader";
import { StatsBoxFallback } from "~components/StatsBoxAlpha";

import { Filter } from "../types";

import {
  ConsumptionsIcon,
  DepositAndPrepaidRentIcon,
  getConsumptionsLabel,
  getDepositAndPrepaidRentLabel,
  getRentLabel,
  Header,
  RentIcon,
  Wrapper,
} from "./KeyNumbers";

type Props = {
  currentFilter: Filter;
};
const KeyNumbersFallback = ({ currentFilter }: Props) => {
  return (
    <>
      <Header
        currentFilter={currentFilter}
        total={<Skeleton dark width="250px" />}
      />
      <Wrapper>
        <StatsBoxFallback icon={RentIcon} label={getRentLabel()} />
        <StatsBoxFallback
          icon={DepositAndPrepaidRentIcon}
          label={getDepositAndPrepaidRentLabel()}
        />
        <StatsBoxFallback
          icon={ConsumptionsIcon}
          label={getConsumptionsLabel()}
        />
      </Wrapper>
    </>
  );
};

export default KeyNumbersFallback;
