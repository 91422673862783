import { forwardRef } from "react";

import styled from "styled-components";

import {
  baseUnit,
  borderColor,
  fontStack,
  primary500,
  textColor,
  transitionDuration,
} from "~components/copied_from_shared/pui";

const Wrapper = styled.button<{
  isActive: boolean;
  index: number;
  small?: boolean;
}>`
  ${fontStack};
  position: relative;
  left: ${({ index }) => `-${index}px`};
  display: flex;
  align-items: center;
  border: 1px solid;
  background-color: transparent;
  border-color: ${({ isActive }) => (isActive ? primary500 : borderColor)};
  height: ${({ small }) => (small ? "30px" : "34px")};
  color: ${({ isActive }) => (isActive ? primary500 : textColor)};
  padding: 0 ${baseUnit}px;
  font-size: ${({ small }) => (small ? "15px" : "17px")};
  margin: 0;
  cursor: pointer;
  transition: ${transitionDuration};
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};
  white-space: nowrap;

  &:hover {
    z-index: 2;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    border-color: ${primary500};
  }

  svg {
    margin-right: ${baseUnit / 2}px;
    width: 18px;
    height: 18px;
  }
`;

type Props = {
  isActive: boolean;
  label: string;
  onClick?: () => void;
  Icon?: any;
  index?: number;
  small?: boolean;
};

const Filter = (
  { isActive, label, onClick, Icon, index, small, ...rest }: Props,
  ref: any
) => {
  return (
    <Wrapper
      small={small}
      index={index || 0}
      isActive={isActive}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {Icon && <Icon />}
      {label}
    </Wrapper>
  );
};

export default forwardRef(Filter);
