import { Skeleton } from "~components/ContentLoader";

import { Amount, Content, Label, Wrapper } from "./SummaryLegend";

type Props = {
  label: string;
  Icon: any;
};
const SummaryLegendFallback = ({ label, Icon }: Props) => (
  <Wrapper>
    <Icon />
    <Content>
      <Label>{label}</Label>
      <Amount>
        <Skeleton width="100px" />
      </Amount>
    </Content>
  </Wrapper>
);

export default SummaryLegendFallback;
