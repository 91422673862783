import { Trans } from "@lingui/macro";

import styled from "styled-components";

import {
  CardEmpty,
  CardEmptyDescription as Description,
} from "~components/Card/Card";

const Wrapper = styled(CardEmpty)`
  flex: 1;
  height: 100%;
`;

const NoDataAvailable = () => (
  <Wrapper>
    <Description>
      <Trans>There's no data matching the current filter</Trans>
    </Description>
  </Wrapper>
);

export default NoDataAvailable;
