import { useEffect, useState } from "react";

import { ContentLoader } from "~components";
import { serializeDate } from "~components/copied_from_shared/date";
import { useUser } from "~hooks";
import { fetch } from "~utils";

import { Filter } from "../types";

import PaymentsAndCharges from "./PaymentsAndCharges";
import PaymentsAndChargesFallback from "./PaymentsAndChargesFallback";

import { Query, ResponseJson } from "~api/dashboard/payments-and-charges-graph";
import { getFromAndToFromDashboardFilter } from "~features/company/DashboardAlpha/utils";

type Props = {
  currentFilter: Filter;
};

const getBody = (query: Omit<Query, "descriptions">): string => {
  return JSON.stringify(query);
};

const PaymentsAndChargesContainer = ({ currentFilter }: Props) => {
  const { viewAsUser, authUser } = useUser();
  const [data, setData] = useState<ResponseJson>();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState();

  const { from, to } = getFromAndToFromDashboardFilter(currentFilter);

  useEffect(() => {
    setLoading(true);
    fetch("/api/dashboard/payments-and-charges-graph", {
      method: "POST",
      body: getBody({
        ownerId: viewAsUser.id,
        from: serializeDate(from),
        to: serializeDate(to),
        currency: viewAsUser.ownerCurrency,
        language: authUser.language,
      }),
    })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        setError(() => {
          throw error; // let error boundary handle this
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentFilter, viewAsUser.id]);

  if (loading || !data) {
    return <PaymentsAndChargesFallback currentFilter={currentFilter} />;
  }

  return (
    <PaymentsAndCharges
      data={data.data}
      chargesAmount={data.chargesAmount}
      paymentsAmount={data.paymentsAmount}
      currentFilter={currentFilter}
    />
  );
};

const PaymentsAndChargesContainerLoader = (props: Props) => (
  <ContentLoader card>
    <PaymentsAndChargesContainer {...props} />
  </ContentLoader>
);

export default PaymentsAndChargesContainerLoader;
