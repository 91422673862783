import { parseISO, startOfMonth, subDays } from "date-fns";

import { formatDate } from "~components/copied_from_shared/date";

import { Filter } from "~features/company/DashboardAlpha/types";

export const getPeriod = (fromDateString: string, toDateString: string) => {
  const from = formatDate(fromDateString);
  const to = formatDate(toDateString);
  return `${from} - ${to}`;
};

export const getFromAndToFromDashboardFilter = (
  filter: Filter
): { from: Date; to: Date } => {
  const { type } = filter;
  const today = new Date();
  if (["default", "last-30-days"].includes(filter.type)) {
    return { from: subDays(today, 30), to: today };
  } else if (filter.type === "last-7-days") {
    return { from: subDays(today, 7), to: today };
  } else if (filter.type === "current-month") {
    return { from: startOfMonth(today), to: today };
  } else if (filter.type === "custom") {
    return { from: parseISO(filter.from), to: parseISO(filter.to) };
  } else {
    throw `Cannot handle filter type: "${type}".`;
  }
};
