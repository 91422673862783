import {
  differenceInMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  format,
  isSameDay,
  isSameYear,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import da from "date-fns/locale/da";
import en from "date-fns/locale/en-US";
import nl from "date-fns/locale/nl";

import { capitalizeFirstLetter } from "~components/copied_from_shared/date";
import { DataMartService } from "~services/data-mart-service";
import { Language } from "~types";

import { Period } from "~features/company/DashboardAlpha/types";

export const isPayment = (transaction: DataMartService.ResponseTransaction) =>
  transaction.transactionType === "Payment";

export const isCharge = (transaction: DataMartService.ResponseTransaction) =>
  transaction.transactionType === "Charge";

export const isRent = (transaction: DataMartService.ResponseTransaction) =>
  isCharge(transaction) &&
  [
    "key:rent",
    "key:garage",
    "key:garden",
    "key:basement",
    "key:parking",
  ].includes(transaction.productKey);

export const isDepositAndPrepaidRent = (
  transaction: DataMartService.ResponseTransaction
) =>
  isCharge(transaction) &&
  [
    "key:deposit",
    "key:prepaid",
    "key:prepaid_change",
    "key:deposit_change",
  ].includes(transaction.productKey);

export const isInPeriod = (
  transaction: DataMartService.ResponseTransaction,
  period: Period
): boolean => {
  return isWithinInterval(
    new Date(
      transaction.transactionType === "Payment"
        ? transaction.registrationDate // for payment transactions we use the registration date
        : transaction.chargeDate // for charge transactions we use the charge date
    ),
    period
  );
};

const getLocale = (language: Language) => {
  if (language === "da") {
    return da;
  } else if (language === "nl") {
    return nl;
  }

  return en;
};

const getFormatRule = (period: Period, now: Date) => {
  if (isSameDay(period.start, period.end)) {
    if (isSameYear(period.start, now)) {
      return "MMM d";
    }
    return "MMM d, yyyy";
  } else {
    if (isSameYear(period.start, now)) {
      return "MMM yyyy";
    }
    return "MMM yyyy";
  }
};

export const getLabel = (period: Period, now: Date, language: Language) => {
  const locale = getLocale(language);
  const formatRule = getFormatRule(period, now);
  return capitalizeFirstLetter(format(period.start, formatRule, { locale }));
};

// If period is a year or longer (e.g. 2020-01-01 to 2020-12-31): group transactions by month
// Otherwise: group by day
export const periodsOfInterval = (start: Date, end: Date): Period[] => {
  if (shouldShowMonthlyInterval(start, end)) {
    return eachMonthOfInterval({ start, end }).map((date) => ({
      start: startOfMonth(date),
      end: endOfMonth(date),
    }));
  } else {
    return eachDayOfInterval({ start, end }).map((date) => ({
      start: startOfDay(date),
      end: endOfDay(date),
    }));
  }
};

export const shouldShowMonthlyInterval = (start: Date, end: Date) => {
  return differenceInMonths(end, start) >= 6;
};
