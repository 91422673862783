import { useEffect, useState } from "react";

import { startOfMonth, subMonths } from "date-fns";
import { getRoute } from "next-type-safe-routes";

import { serializeDate } from "~components/copied_from_shared/date";
import { useUser } from "~hooks";
import { shouldShowMonthlyInterval } from "~transformers/company/utils";
import { fetch } from "~utils";

import { Filter } from "../types";

import DistributionOfCharges from "./DistributionOfCharges";
import DistributionOfChargesFallback from "./DistributionOfChargesFallback";

import { Query, ResponseJson } from "~api/dashboard/distribution-of-charges";
import { ChartData } from "~features/company/DashboardAlpha/DistributionOfCharges/types";
import { getFromAndToFromDashboardFilter } from "~features/company/DashboardAlpha/utils";

type Props = {
  currentFilter: Filter;
};

const getBody = (query: Query): string => {
  return JSON.stringify(query);
};

const getDefaultFromAndTo = () => ({
  from: startOfMonth(subMonths(new Date(), 12)),
  to: new Date(),
});

const DistributionOfChargesContainer = ({ currentFilter }: Props) => {
  const { viewAsUser, authUser } = useUser();
  const [response, setResponse] = useState<ResponseJson>();
  const [loading, setLoading] = useState<boolean>(true);
  const [, setError] = useState();

  const { from, to } =
    currentFilter.type === "default"
      ? getDefaultFromAndTo()
      : getFromAndToFromDashboardFilter(currentFilter);

  const endpoint = getRoute("/api/dashboard/distribution-of-charges");
  useEffect(() => {
    setLoading(true);
    fetch(endpoint, {
      method: "POST",
      body: getBody({
        ownerId: viewAsUser.id,
        from: serializeDate(from),
        to: serializeDate(to),
        language: authUser.language,
        currency: viewAsUser.ownerCurrency,
        groupBy: shouldShowMonthlyInterval(from, to) ? "Month" : "Day",
      }),
    })
      .then((json) => {
        setResponse(json);
      })
      .catch((error) => {
        setError(() => {
          return undefined;
          // throw error; // let error boundary handle this
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentFilter, viewAsUser.id]);

  if (loading || !response) {
    return <DistributionOfChargesFallback currentFilter={currentFilter} />;
  }

  return (
    <DistributionOfCharges
      currency={viewAsUser.ownerCurrency}
      availableDescriptionTypes={response.availableDescriptionTypes}
      chartData={response.chartData as ChartData[]}
      currentFilter={currentFilter}
    />
  );
};

export default DistributionOfChargesContainer;
