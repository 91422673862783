import { Trans } from "@lingui/macro";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

import {
  fontStack,
  pampas100,
  pampas300,
  textColor,
} from "~components/copied_from_shared/pui";
import { useUser } from "~hooks";
import useDimensions from "~hooks/useDimensions";
import { OwnerCurrency } from "~services/entities";
import { moneyFromNumber } from "~utils/money";

import Tooltip from "./Tooltip";
import { ChartData, DescriptionObject } from "./types";

export const chartHeight = 350;

const borderColor = pampas100;

const formatAmount = (val: number, currency: OwnerCurrency) => {
  if (val === 0) {
    return "0";
  }
  return `${(val / 1000).toFixed(0)}K${moneyFromNumber(
    val / 1000,
    currency
  ).format({ pattern: "!" })}`;
};

const Wrapper = styled.div`
  ${fontStack};
  flex: 1;
`;

export type Props = {
  data: ChartData[];
  currency: OwnerCurrency;
  selectedDescriptions: DescriptionObject[];
};
const DistributionOfChargesChart = ({
  data,
  currency,
  selectedDescriptions,
}: Props) => {
  const { viewAsUser } = useUser();
  const { ref, width } = useDimensions();
  const barWidth = width < 600 ? width / 15 : width / 25;

  return (
    <Wrapper ref={ref}>
      <ResponsiveContainer height={330} width="100%">
        <ComposedChart data={data}>
          <XAxis
            padding={{ right: 20 }}
            interval="preserveStartEnd"
            minTickGap={50}
            axisLine={false}
            tickLine={false}
            dataKey="date"
            tick={{ fill: textColor }}
            tickMargin={10}
          />
          <YAxis
            width={80}
            allowDataOverflow={true}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
            tickFormatter={(amount) => formatAmount(amount, currency)}
            tick={{ fill: textColor }}
            stroke={borderColor}
          />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={pampas300}
            vertical={false}
          />
          <ReChartsTooltip
            cursor={{ fill: pampas100 }}
            content={(props) => (
              <Tooltip {...props} currency={viewAsUser.ownerCurrency} />
            )}
          />
          {selectedDescriptions.map((description, index) => {
            const isLast = index === selectedDescriptions.length - 1;

            return (
              <Bar
                key={`${description.type}-bar`}
                barSize={barWidth}
                stackId="a"
                radius={isLast ? [3, 3, 0, 0] : undefined}
                dataKey={description.type}
                name={description.displayName}
                fill={description.color}
                isAnimationActive={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default DistributionOfChargesChart;
