import { flatten } from "lodash";

import { ContentLoader } from "~components";
import { useResource } from "~hooks";

import PortfolioRightNow from "./PortfolioRightNow";
import PortfolioRightNowFallback from "./PortfolioRightNowFallback";
import {
  getBusinessArea,
  getNumTenanciesWithBusinessArea,
  getNumTenanciesWithTotalArea,
  getTotalArea,
} from "./utils";

import { Response } from "~api/properties/portfolio-right-now";
import {
  getNumTenancies,
  getNumVacantTenancies,
} from "~features/property/Properties/utils";

function PortfolioRightNowContainer() {
  const route = "/api/properties/portfolio-right-now";
  const [properties = []] = useResource<Response>(route, {
    suspense: true,
  });

  const tenancies = flatten(properties.map((p) => p.tenancies));
  const numTenancies = getNumTenancies(properties);
  const numVacantTenancies = getNumVacantTenancies(properties);
  const numTenanciesWithTotalArea = getNumTenanciesWithTotalArea(tenancies);
  const numTenanciesWithBusinessArea =
    getNumTenanciesWithBusinessArea(tenancies);

  return (
    <PortfolioRightNow
      numTenancies={numTenancies}
      hasTenanciesWithoutTotalArea={numTenancies > numTenanciesWithTotalArea}
      hasTenanciesWithoutBusinessArea={
        numTenancies > numTenanciesWithBusinessArea
      }
      numOccupiedTenancies={numTenancies - numVacantTenancies}
      tenanciesAreaTotal={getTotalArea(tenancies)}
      tenanciesBusinessAreaTotal={getBusinessArea(tenancies)}
    />
  );
}

const PortfolioRightNowContainerLoader = () => (
  <ContentLoader LoadingFallback={PortfolioRightNowFallback}>
    <PortfolioRightNowContainer />
  </ContentLoader>
);

export default PortfolioRightNowContainerLoader;
