import { t, Trans } from "@lingui/macro";

import styled from "styled-components";

import { formatMonthAndYear } from "~components/copied_from_shared/date";
import {
  baseUnit,
  fontFamilySerif,
  fontStack,
  Paragraph,
  primary500,
  textColor,
} from "~components/copied_from_shared/pui";
import {
  Database,
  DollarSign,
  PieChart,
} from "~components/copied_from_shared/pui/icons";
import StatsBox from "~components/StatsBoxAlpha";
import { useUser } from "~hooks";
import useDimensions from "~hooks/useDimensions";
import money from "~utils/money";

import Grid from "../Grid";
import { Filter } from "../types";
import { getPeriod } from "../utils";

export const RentIcon = <DollarSign color={primary500} />;
export const DepositAndPrepaidRentIcon = <PieChart color={primary500} />;
export const ConsumptionsIcon = <Database color={primary500} />;

export const getRentLabel = () => t`Rent`;
export const getDepositAndPrepaidRentLabel = () => t`Deposit and prepaid rent`;
export const getConsumptionsLabel = () => t`Aconto, operations, etc.`;

export const Wrapper = ({ children }: any) => {
  const { ref, width } = useDimensions();
  return (
    <Grid ref={ref} horizontal={width > 800}>
      {children}
    </Grid>
  );
};

export const HeaderWrapper = styled.div`
  margin-bottom: ${baseUnit * 3}px;
`;

export const Caption = styled(Paragraph).attrs({ size: "huge" })`
  margin-bottom: ${baseUnit / 2}px;
`;

export const TotalAmount = styled.div`
  ${fontStack};
  font-family: ${fontFamilySerif};
  color: ${textColor};
  font-size: 36px;
  font-weight: bold;
`;

export type FeatureProps = {
  rent: string;
  depositAndPrepaidRent: string;
  consumptions: string;
  total: string;
};

export type Props = FeatureProps & {
  currentFilter: Filter;
};

const getFilterText = (currentFilter: Filter, language: string) => {
  if (
    currentFilter.type === "default" ||
    currentFilter.type === "current-month"
  ) {
    const monthAndYear = formatMonthAndYear(new Date(), language);
    return t`Charges for ${monthAndYear}`;
  } else if (currentFilter.type === "custom") {
    const period = getPeriod(currentFilter.from, currentFilter.to);
    return t`Charges from ${period}`;
  } else if (currentFilter.type === "last-30-days") {
    return t`Charges, last 30 days`;
  } else if (currentFilter.type === "last-7-days") {
    return t`Charges, last 7 days`;
  }
};

type HeaderProps = {
  currentFilter: Filter;
  total: any;
};
export const Header = ({ currentFilter, total }: HeaderProps) => {
  const user = useUser();
  return (
    <HeaderWrapper>
      <Caption>
        <Trans>{getFilterText(currentFilter, user.authUser.language)}</Trans>
      </Caption>
      <TotalAmount>{total}</TotalAmount>
    </HeaderWrapper>
  );
};

const KeyNumbers = ({
  rent,
  depositAndPrepaidRent,
  consumptions,
  total,
  currentFilter,
}: Props) => {
  return (
    <>
      <Header currentFilter={currentFilter} total={money(total).format()} />
      <Wrapper>
        <StatsBox
          icon={RentIcon}
          label={getRentLabel()}
          value={money(rent).format()}
        />
        <StatsBox
          icon={DepositAndPrepaidRentIcon}
          label={getDepositAndPrepaidRentLabel()}
          value={money(depositAndPrepaidRent).format()}
        />
        <StatsBox
          icon={ConsumptionsIcon}
          label={getConsumptionsLabel()}
          value={money(consumptions).format()}
        />
      </Wrapper>
    </>
  );
};

export default KeyNumbers;
