import DayPicker, { DayPickerProps } from "react-day-picker";
import styled from "styled-components";

import {
  baseUnit,
  blue500,
  fontFamilySans,
  textColor,
  textColorDiscreet,
} from "~components/copied_from_shared/pui";

import "react-day-picker/lib/style.css";

const Wrapper = styled.div`
  font-family: ${fontFamilySans};

  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }

  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .DayPicker-Month {
    margin: ${baseUnit}px 0;
  }

  .DayPicker-Month:last-child {
    margin-left: ${baseUnit}px;
  }

  .DayPicker-wrapper {
    padding: 0;
  }

  .DayPicker-Day {
    color: ${textColor};
  }

  .DayPicker-Day--today {
    color: ${blue500};
  }

  .DayPicker-Day--disabled {
    color: ${textColorDiscreet};
  }
`;

const RangePicker = (props: DayPickerProps) => {
  return (
    <Wrapper>
      <DayPicker className="Selectable" numberOfMonths={2} {...props} />
    </Wrapper>
  );
};

export default RangePicker;
