import { useEffect } from "react";

import { useRouter, useUser } from "~hooks";

import DashboardAlpha from "~features/company/DashboardAlpha";

function HomePage() {
  const router = useRouter();
  const { isPaymentsCustomer, isPropertiesCustomer, viewAsUser } = useUser();

  useEffect(() => {
    if (viewAsUser && isPaymentsCustomer) {
      router.replace({
        route: "/tenancies",
        query: { viewAs: viewAsUser.id },
      });
    }
  });

  if (viewAsUser && isPropertiesCustomer) {
    return <DashboardAlpha />;
  }

  return null;
}

export default HomePage;
