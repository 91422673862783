import styled from "styled-components";

import {
  baseUnit,
  fontStack,
  textColor,
  white,
} from "~components/copied_from_shared/pui";
import {
  borderRadius,
  boxShadow,
} from "~components/copied_from_shared/pui/components/Card/Card";

export const ChartCard = styled.div`
  padding: ${baseUnit * 3}px;
  border-radius: ${borderRadius};
  background-color: ${white};
  box-shadow: ${boxShadow};
`;

export const ChartCardHeader = styled.div`
  margin-bottom: ${baseUnit * 3}px;
  padding-bottom: ${baseUnit * 2}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChartCardAnchor = styled.a`
  display: flex;
  align-items: center;

  svg {
    margin-left: ${baseUnit / 3}px;
  }
`;

export const ChartCardTitle = styled.div`
  ${fontStack};
  color: ${textColor};
  font-size: 19px;
  line-height: 1;
  margin-bottom: ${baseUnit / 2}px;
`;

export default ChartCard;
