import { format } from "date-fns";

import { OwnerCurrency } from "~services/entities";
import { moneyFromNumber } from "~utils/money";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatMonth = (month: number) => {
  return capitalizeFirstLetter(format(new Date(1970, month, 1), "MMM"));
};

export const formatAmount = (val: number, currency: OwnerCurrency) => {
  if (val < 0) return "";
  const valueInKilos = val / 1000;
  if (valueInKilos < 1) {
    return moneyFromNumber(val, currency).format({
      precision: 0,
    });
  }
  const unit = moneyFromNumber(0, currency).format({ pattern: "!" });
  return moneyFromNumber(valueInKilos, currency).format({
    symbol: `K${unit}`,
    precision: 0,
  });
};
