import { t } from "@lingui/macro";

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled, { keyframes } from "styled-components";

import {
  black,
  fontStack,
  pampas300,
  textColor,
} from "~components/copied_from_shared/pui";
import useDimensions from "~hooks/useDimensions";
import { OwnerCurrency } from "~services/entities";

import {
  colorConsumptions,
  colorDepositAndPrepaidRent,
  colorRent,
} from "../tokens";

import Tooltip from "./Tooltip";
import { ChartData } from "./types";
import { formatAmount } from "./utils";

export const chartHeight = 320;

const borderColor = pampas300;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  ${fontStack};
  animation: ${fadeIn} 1s;
`;

type Props = {
  data: ChartData[];
  currency: OwnerCurrency;
};
const PaymentsAndChargesChart = ({ data, currency }: Props) => {
  const { ref, width } = useDimensions();
  const barWidth = width < 600 ? width / 15 : width / 30;

  return (
    <Wrapper ref={ref}>
      <ResponsiveContainer height={chartHeight} width="100%">
        <ComposedChart data={data}>
          <XAxis
            padding={{ right: 20 }}
            interval="preserveStartEnd"
            minTickGap={20}
            axisLine={false}
            tickLine={false}
            dataKey="date"
            tick={{ fill: textColor }}
            tickMargin={10}
          />
          <YAxis
            width={80}
            allowDataOverflow={true}
            tickMargin={10}
            axisLine={false}
            tickLine={false}
            tickFormatter={(amount) => formatAmount(amount, currency)}
            tick={{ fill: textColor }}
            stroke={borderColor}
          />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={pampas300}
            vertical={false}
          />
          <ReChartsTooltip cursor={{ fill: pampas300 }} content={Tooltip} />
          <Bar
            barSize={barWidth}
            stackId="a"
            dataKey="rent"
            name={t`Rent`}
            fill={colorRent}
            background={false}
            isAnimationActive={false}
          />
          <Bar
            barSize={barWidth}
            stackId="a"
            dataKey="depositAndPrepaidRent"
            name={t`Deposit and prepaid rent`}
            fill={colorDepositAndPrepaidRent}
            isAnimationActive={false}
          />
          <Bar
            barSize={barWidth}
            radius={[3, 3, 0, 0]}
            stackId="a"
            dataKey="consumptions"
            name={t`Aconto, operations, etc.`}
            fill={colorConsumptions}
            isAnimationActive={false}
          />
          <Line
            strokeWidth={2}
            type="linear"
            dataKey="payments"
            stroke={black}
            dot={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default PaymentsAndChargesChart;
