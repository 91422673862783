import {
  black,
  primary300,
  primary400,
  primary500,
} from "~components/copied_from_shared/pui";

export const colorRent = primary500;
export const colorDepositAndPrepaidRent = primary400;
export const colorConsumptions = primary300;
export const colorPayments = black;
