import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

import { useEffect, useState } from "react";

import { add, isEqual, isSameMonth, parseISO } from "date-fns";
import { BeforeModifier, DateUtils, RangeModifier } from "react-day-picker";
import styled from "styled-components";

import { serializeDate } from "~components/copied_from_shared/date";
import {
  baseUnit,
  Button,
  Heading,
  white,
} from "~components/copied_from_shared/pui";
import { X } from "~components/copied_from_shared/pui/icons";
import { Company as CompanyType } from "~types";

import RangePicker from "./RangePicker";
import { DateRange } from "./types";

export type FeatureProps = CompanyType;

const Wrapper = styled.div`
  padding: ${baseUnit * 2}px;
`;

const Divider = styled.div`
  margin: ${baseUnit}px 0px;
`;
const BottomActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: ${baseUnit}px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5em;
`;

const CloseButton = styled.button`
  background: ${white};
  width: ${baseUnit * 3}px;
  height: ${baseUnit * 3}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: auto;
`;

const initialRange = {
  from: undefined,
  to: undefined,
};

const isSelectingFirstDay = (
  from: Date | undefined,
  to: Date | undefined,
  day: Date
) => {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
};

export type Props = {
  onApplyDateRange: (dateRange: DateRange) => void;
  onClose: () => void;
  dateRange: DateRange | null;
};
const DateRangePicker = ({ onApplyDateRange, dateRange, onClose }: Props) => {
  const [range, setRange] = useState<RangeModifier>(initialRange);
  const from = range.from === null ? undefined : range.from;
  const to = range.to === null ? undefined : range.to;
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(to);
  const [dayChanged, setDayChanged] = useState(false);
  const [lastClickedDay, setLastClickedDay] = useState<Date>(to ?? new Date());

  const Months = [
    t`January`,
    t`February`,
    t`March`,
    t`April`,
    t`May`,
    t`June`,
    t`July`,
    t`August`,
    t`September`,
    t`October`,
    t`November`,
    t`December`,
  ];

  const WeekDays = [
    t`Monday`,
    t`Tuesday`,
    t`Wednesday`,
    t`Thursday`,
    t`Friday`,
    t`Saturday`,
    t`Sunday`,
  ];
  const WeekDaysShort = [t`Mo`, t`Tu`, t`We`, t`Th`, t`Fr`, t`Sa`, t`Su`];

  useEffect(() => {
    if (dayChanged) {
      return;
    }
    if (dateRange) {
      const { from, to } = dateRange;
      if (from && to) {
        setRange({
          from: parseISO(from),
          to: parseISO(to),
        });
        setEnteredTo(parseISO(to));
        setLastClickedDay(parseISO(to));
      }
    } else {
      setRange(initialRange);
    }
  }, [dateRange]);

  const isSelectedDateWithinTheExistingRange = (day: Date): boolean => {
    return from !== undefined && to !== undefined && day >= from && day <= to;
  };

  const handleDayClick = (day: Date) => {
    setDayChanged(true);
    setLastClickedDay(day);
    if (isSelectedDateWithinTheExistingRange(day)) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setRange({
        from: day,
        to: undefined,
      });
      setEnteredTo(undefined);
    } else {
      setRange({ ...range, to: day });
      setEnteredTo(day);
    }
  };

  const handleResetClick = () => {
    setDayChanged(true);
    setRange(initialRange);
    setEnteredTo(undefined);
  };

  const handleDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const onApply = () => {
    onApplyDateRange({
      from: from !== undefined ? serializeDate(from) : "",
      to: to !== undefined ? serializeDate(to) : "",
    });
  };

  const canApply = range.from && range.to;

  const getMonth = (): Date => {
    if (dayChanged && to) {
      if (!(from && isSameMonth(from, to) && isEqual(to, lastClickedDay))) {
        return add(lastClickedDay, { months: -1 });
      }
    } else {
      if (from && to) {
        if (isSameMonth(add(from, { months: 1 }), to)) {
          return from;
        } else {
          return to;
        }
      }
    }
    return lastClickedDay;
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <Heading size="small">
          <Trans>Choose period</Trans>
        </Heading>
        <CloseButton onClick={onClose}>
          <X size={36} />
        </CloseButton>
      </HeaderContainer>
      <Divider />
      <RangePicker
        onDayClick={handleDayClick}
        selectedDays={[from, { from, to: enteredTo }]}
        modifiers={{ start: from, end: enteredTo }}
        months={Months}
        weekdaysLong={WeekDays}
        weekdaysShort={WeekDaysShort}
        firstDayOfWeek={0}
        onDayMouseEnter={handleDayMouseEnter}
        {...(from && {
          disabledDays: { before: from } as BeforeModifier,
        })}
        {...{
          month: getMonth(),
        }}
      />
      <Divider />
      <BottomActionContainer>
        <Button onClick={handleResetClick}>
          <Trans>Reset</Trans>
        </Button>
        <Button
          onClick={onApply}
          disabled={!canApply}
          tooltip={!canApply ? t`Please choose a start and end date` : null}
          primary
        >
          <Trans>Apply</Trans>
        </Button>
      </BottomActionContainer>
    </Wrapper>
  );
};

export default DateRangePicker;
