import { StatsBoxFallback } from "~components/StatsBoxAlpha";

import { labels, Wrapper } from "./PortfolioRightNow";

const PortfolioRighNowFallback = () => (
  <Wrapper>
    <StatsBoxFallback size="small" label={labels.tenancies()} />
    <StatsBoxFallback size="small" label={labels.occupied()} />
    <StatsBoxFallback size="small" label={labels.totalArea()} />
    <StatsBoxFallback size="small" label={labels.businessArea()} />
  </Wrapper>
);

export default PortfolioRighNowFallback;
