import { TenancySummary } from "~types";

export const getTotalArea = (tenancies: TenancySummary[]) => {
  return tenancies.reduce((total, tenancy) => {
    const totalArea = tenancy.totalArea ? parseFloat(tenancy.totalArea) : 0;
    return total + totalArea;
  }, 0);
};

export const getBusinessArea = (tenancies: TenancySummary[]) => {
  return tenancies.reduce((total, tenancy) => {
    const businessArea = tenancy.businessArea
      ? parseFloat(tenancy.businessArea)
      : 0;
    return total + businessArea;
  }, 0);
};

export const getNumTenanciesWithTotalArea = (tenancies: TenancySummary[]) => {
  return tenancies.reduce((total, { totalArea }) => {
    return totalArea ? total + 1 : total;
  }, 0);
};

export const getNumTenanciesWithBusinessArea = (
  tenancies: TenancySummary[]
) => {
  return tenancies.reduce((total, { businessArea }) => {
    return businessArea ? total + 1 : total;
  }, 0);
};
